import React, { useState } from 'react';
import styled from 'styled-components';
import { ThemedProps } from '../../styles/theme';
import { FunctionComponent } from 'react';
import Checkbox from '../checkbox';
import AsideMenu from '../aside-menu';
import FiltersIcon from '../../static/icons/icon-filters.inline.svg';
import { navigate } from '@reach/router';
import { lockGlobalScroll } from '../../utils/utils';

const Filters = styled.div`
    display: flex;
    flex-flow: column;
    & > * {
        margin: 0.25rem 0;
    }
`;

const FiltersTitle = styled.h2<ThemedProps>`
    font-size: ${({ theme }: ThemedProps) => theme.typography.sizes.normal};
    font-weight: ${({ theme }: ThemedProps) => theme.typography.weights.bold};
    margin: 0;
`;

const CustomFiltersMenuIcon = styled(FiltersIcon)<ThemedProps>`
    width: 1rem;
    height: 1rem;
`;

interface Props {
    isSaleChecked: boolean;
    isLatestChecked: boolean;
    prepareSaleUrl: (checked: boolean) => string;
    prepareLatestUrl: (checked: boolean) => string;
}

const FiltersMenu: FunctionComponent<Props> = ({
    isSaleChecked,
    isLatestChecked,
    prepareSaleUrl,
    prepareLatestUrl,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleSetIsOpen = () =>
        setIsOpen((state) => {
            lockGlobalScroll(!state);
            return !state;
        });

    const handleSaleChange = (checked: boolean) => {
        const saleUrl = prepareSaleUrl(checked);
        navigate(saleUrl);
    };

    const handleLatestChange = (checked: boolean) => {
        const latestUrl = prepareLatestUrl(checked);
        navigate(latestUrl);
    };

    return (
        <AsideMenu
            isOpen={isOpen}
            handleSetIsOpen={handleSetIsOpen}
            openingButtonTitle="Filtry"
            closingButtonTitle="Zamknij"
            openingButtonIcon={<CustomFiltersMenuIcon />}
        >
            <Filters>
                <FiltersTitle>Filtry</FiltersTitle>
                <Checkbox label="Promocje tygodnia" checked={isSaleChecked} onChange={handleSaleChange} />
                <Checkbox label="Nowości" checked={isLatestChecked} onChange={handleLatestChange} />
        
            </Filters>
        </AsideMenu>
    );
};

export default FiltersMenu;
